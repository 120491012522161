import React from 'react'
import useSWR from 'swr'

import WordComponent from './WordComponent'

const fetcher = (url) => fetch(url).then((res) => res.json())

function LessonComponent({ item }) {
  const { data, error } = useSWR(
    process.env.REACT_APP_JSON_SERVER_URL + '/word?lessonId=' + item?.id,
    fetcher
  )

  if (error) return 'An error has occurred. (Words)'
  if (!data) return 'Words are loading...'
  console.log('LessonComponent')
  return (
    <React.Fragment>
      <div className="lesson">
        <h1>
          Lesson {item.id}
          {item?.name ? '. ' + item.name : ''}
        </h1>
        <span>{item.date}</span>
      </div>
      {data.map((item, index) => {
        return <WordComponent item={item} index={index} key={index} />
      })}
    </React.Fragment>
  )
}

export default LessonComponent
