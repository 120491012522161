import React from 'react'
import useSWR from 'swr'
import LessonComponent from './LessonComponent'

const fetcher = (url) => fetch(url).then((res) => res.json())

function DictionaryComponent() {
  const { data, error } = useSWR(
    process.env.REACT_APP_JSON_SERVER_URL + '/lesson?_sort=id&_order=asc',
    fetcher
  )

  if (error) return 'An error has occurred.'
  if (!data) return 'Lessons are loading...'
  console.log('DictionaryComponent')
  return (
    <div className="container">
      {data.map((item, index) => {
        return <LessonComponent item={item} key={index} />
      })}
    </div>
  )
}

export default DictionaryComponent

//
