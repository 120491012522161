import React, { useState } from 'react'
import useSWR from 'swr'

const fetcher = (url) => fetch(url).then((res) => res.json())

const FlipCards = ({ les, flipOrder, hideExample }) => {
  const [state, setState] = useState(false)
  const [current, setCurrent] = useState(0)

  let lesStr = les !== null ? 'lessonId=' + les : '_sort=lessonId&_order=asc'

  const { data, error } = useSWR(
    process.env.REACT_APP_JSON_SERVER_URL + '/word?' + lesStr,
    fetcher
  )

  const toggleCard = () => {
    if (!state) setState(true)
    else {
      setState(false)
      if (current >= data.length - 1) setCurrent(0)
      else setCurrent(current + 1)
    }
  }
  if (error) return 'An error has occurred. (Loading words)'
  if (!data || data === []) return 'Words are loading...'

  let endState = flipOrder ? state : !state

  return (
    <div className="flipCards" onClick={toggleCard} onKeyDown={toggleCard}>
      <div className="flipCard">
        {endState ? (
          <React.Fragment>
            <h2>{data[current]?.descr ? data[current]?.descr : '-'}</h2>{' '}
            <p>
              {data[current]?.example && !hideExample
                ? data[current]?.example
                : ''}
            </p>
          </React.Fragment>
        ) : (
          <h1>{data[current]?.en ? data[current]?.en : '-'}</h1>
        )}
      </div>
    </div>
  )
}

export default FlipCards
