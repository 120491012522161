import { useState } from 'react'

import DictionaryComponent from './components/DictionaryComponent'
import FlipCards from './components/FlipCards'
import LessonsBar from './components/LessonsBar'

function App() {
  const [page, setPage] = useState(false) // false - Dic, true - flipCards
  const [les, setLes] = useState(null)
  const [flipOrder, setFlipOrder] = useState(false)
  const [hideExample, setHideExample] = useState(true)

  return (
    <div className="container">
      <div className="navbar">
        <div
          className="navbarBtn"
          onClick={() => {
            setPage(!page)
          }}
        >
          {page ? 'FlipCards' : 'Dictionary'}
        </div>
        <div
          className="navbarBtn"
          onClick={() => {
            setFlipOrder(!flipOrder)
          }}
        >
          {flipOrder ? 'Direct' : 'Reverse'}
        </div>
        <div
          className="navbarBtn"
          onClick={() => {
            setHideExample(!hideExample)
          }}
        >
          {hideExample ? 'Hide ex.' : 'Show ex.'}
        </div>
      </div>
      <LessonsBar page={page} les={les} setLes={setLes} />
      {page ? (
        <DictionaryComponent />
      ) : (
        <FlipCards les={les} flipOrder={flipOrder} hideExample={hideExample} />
      )}
    </div>
  )
}

export default App
