const WordComponent = ({ item, index }) => {
  let add = index / 2 - Math.trunc(index / 2) > 0 ? 'gray' : ''

  return (
    <div className={'line ' + add}>
      <div className="word">{item?.en}</div>
      <div className="description">
        {item?.descr}
        <div className="example">{item?.example}</div>
      </div>
    </div>
  )
}

export default WordComponent
