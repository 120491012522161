import useSWR from 'swr'

const fetcher = (url) => fetch(url).then((res) => res.json())

function LessonsBar({ page, les, setLes }) {
  const { data, error } = useSWR(
    process.env.REACT_APP_JSON_SERVER_URL + '/lesson?_sort=id&_order=asc',
    fetcher
  )

  if (page) return ''
  if (error) return 'An error has occurred. (App)'
  if (!data) return 'loading...'

  return (
    <div className="lessonsBar">
      {!page
        ? data.map((item, index) => {
            return (
              <div
                key={index}
                className={`lessonsBarBtn ${item.id === les ? 'bggray' : ''}`}
                onClick={() => {
                  if (les !== item.id) setLes(item.id)
                  else setLes(null)
                }}
              >
                {item.id}
              </div>
            )
          })
        : ''}
    </div>
  )
}

export default LessonsBar
